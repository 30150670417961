import React, { useState, useEffect } from 'react';
import './SlidingBanner.css';

const SlidingBanner = () => {
    const [banners, setBanners] = useState([]);
    const [currentSlide, setCurrentSlide] = useState(0);
    const [slideDirection, setSlideDirection] = useState('forward');
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchBanners = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/banner_list/`);

                if (!response.ok) {
                    throw new Error(`Failed to fetch banners: ${response.status}`);
                }
                const data = await response.json();
                setBanners(data.banners);
            } catch (error) {
                console.error('Error fetching banners:', error);
                setError(error.message);
            }
        };

        fetchBanners();
    }, []);

    useEffect(() => {
        const maxSlide = banners.length;
        let slideInterval = setInterval(() => {
            setCurrentSlide((prevSlide) => {
                let newSlide;
                if (slideDirection === 'forward') {
                    newSlide = prevSlide + 1;
                    if (newSlide >= maxSlide) {
                        newSlide = maxSlide - 1;
                        setSlideDirection('backward');
                    }
                } else {
                    newSlide = prevSlide - 1;
                    if (newSlide < 0) {
                        newSlide = 0;
                        setSlideDirection('forward');
                    }
                }
                return newSlide;
            });
        }, 5000); // Stay for 4 seconds, move in 1 second.

        return () => clearInterval(slideInterval);
    }, [banners.length, slideDirection]);

    if (error) {
        return <div className="error-message">Error loading banners: {error}</div>;
    }

    return (
        <div className="sliding-banner-container">
            <div className="sliding-banner">
                {banners.map((banner, index) => (
                    <div 
                        key={banner.unique_name} 
                        className={`banner-item ${currentSlide === index ? 'active-slide' : ''}`}
                        style={{transition: `transform 1s ease`, transform: `translateX(${currentSlide * -100}%)`}}
                    >
                        <img src={`${process.env.REACT_APP_API_URL}${banner.image_url}`} alt={`Banner ${index + 1}`} />

                    </div>
                ))}
                {banners.length === 0 && !error && (
                    <div className="no-banners-message">No banners available.</div>
                )}
            </div>
        </div>
    );
};

export default SlidingBanner;
