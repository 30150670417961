import React, { useState, useEffect } from 'react';
const baseUrl = `${process.env.REACT_APP_API_URL}/api`;
const RefundPolicy = () => {
   // State to store the fetched data
   const [refundPolicy, setRefundPolicy] = useState({});

   // Function to fetch Refund Policy content
   const fetchRefundPolicyContent = async () => {
       try {
           // Update the endpoint to the one serving your Refund Policy data
           const response = await fetch(`${baseUrl}/refund-policy/`);

           const data = await response.json();
           setRefundPolicy(data); // Update the state with the fetched Refund Policy data
       } catch (error) {
           console.error("Error fetching Refund Policy content:", error);
       }
   };

   // useEffect to fetch data on component mount
   useEffect(() => {
       fetchRefundPolicyContent();
   }, []); // Empty dependency array means this effect runs once on mount

   return (
       <div>
           <h2 style={{textAlign: 'center'}}>{refundPolicy.title}</h2>
           {/* Rendering the content as HTML */}
           {/* Make sure your content is sanitized to avoid XSS attacks */}
           <div dangerouslySetInnerHTML={{ __html: refundPolicy.content }} />
           <p>Last Updated: {new Date(refundPolicy.last_updated).toLocaleString()}</p>
       </div>
   );
};

export default RefundPolicy;
