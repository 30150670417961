import React, { useState, useEffect } from 'react'; // Import useEffect
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './CartPage.css';

const CartPage = ({ cartItems, removeItem, user, clearCart }) => {
  const [deliveryAddress, setDeliveryAddress] = useState('');
  const [customTexts, setCustomTexts] = useState(
    cartItems.reduce((acc, item) => ({ ...acc, [item.id]: '' }), {})
  );
  const [quantities, setQuantities] = useState(
    cartItems.reduce((acc, item) => ({ ...acc, [item.id]: item.quantity || 1 }), {})
  );
  const [images, setImages] = useState(
    cartItems.reduce((acc, item) => ({ ...acc, [item.id]: '' }), {})
  );
  const navigate = useNavigate();
  
  useEffect(() => {
    // If cartItems array becomes empty, navigate to '/'
    if (cartItems.length === 0) {
      navigate('/');
    }
  }, [cartItems, navigate]);

  
  
  const handleRemoveItem = (itemId) => {
    removeItem(itemId);
    const newCustomTexts = { ...customTexts };
    delete newCustomTexts[itemId];
    setCustomTexts(newCustomTexts);

    const newQuantities = { ...quantities };
    delete newQuantities[itemId];
    setQuantities(newQuantities);

    const newImages = { ...images };
    delete newImages[itemId];
    setImages(newImages);
  };
  
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!deliveryAddress.trim()) {
      alert('Please enter a delivery address.');
      return;
    }
    
    // Function to check if customization requirements are met for an item
    const isCustomizationMet = (item) => {
      const hasText = customTexts[item.id].trim() !== '';
      const hasImage = images[item.id] !== '';
      switch (item.customizationType) {
        case 'text':
          return hasText;
        case 'image':
          return hasImage;
        case 'both':
          return hasText && hasImage;
        default:
          return true; // No customization required or item does not require customization
      }
    };
  
    // Find items that don't meet customization requirements
    const itemsMissingCustomization = cartItems.filter(item => !isCustomizationMet(item));
  
    if (itemsMissingCustomization.length > 0) {
      // Construct a warning message based on missing customizations
      const warningMessages = itemsMissingCustomization.map(item => {
        switch (item.customizationType) {
          case 'text':
            return `Please enter custom text for "${item.name}".`;
          case 'image':
            return `Please upload an image for "${item.name}".`;
          case 'both':
            return `Please enter custom text and upload an image for "${item.name}".`;
          default:
            return ''; // This case should ideally never be reached due to the default in isCustomizationMet
        }
      }).join('\n');
  
      alert(warningMessages);
      return;
    }
  
    const totalAmount = calculateTotalAmount();
  
    try {
      const keyResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/get-razorpay-keys/`);
      const { keyId } = keyResponse.data;
  
      var options = {
        key: keyId,
        amount: totalAmount * 100,
        currency: "INR",
        name: "Middle Man",
        description: "For testing purpose",
        handler: async function(response) {
          const orderData = {
            cartItems: cartItems.map((item) => ({
              id: item.id,
              customText: customTexts[item.id],
              quantity: quantities[item.id],
              price: item.price,
              image: images[item.id], // Include the image in the order data
              selectedOption: item.selectedOption 
            })),
            totalAmount: totalAmount,
            deliveryAddress,
            userId: user ? user.id : null,
            paymentId: response.razorpay_payment_id
          };
  
          const orderResponse = await axios.post(`${process.env.REACT_APP_API_URL}/api/orders/`, orderData, {
            headers: {
              'Content-Type': 'application/json',
            },
          });
  
          if (orderResponse.data.success) {
            alert(`Order placed successfully! Payment ID: ${response.razorpay_payment_id}`);
            clearCart();
            navigate('/');
          } else {
            console.error('Failed to place order');
          }
        },
        prefill: {
          name: user?.fullName || "Guest",
          email: user?.email || "",
          contact: user?.contactNumber || ""
        },
        notes: {
          address: "Razorpay Corporate office"
        },
        theme: {
          color: "#3399cc"
        }
      };
  
      var pay = new window.Razorpay(options);
      pay.open();
    } catch (error) {
      console.error('Error fetching Razorpay key or initializing payment:', error);
    }
  };
  
  const handleCustomTextChange = (itemId, text) => {
    setCustomTexts({ ...customTexts, [itemId]: text });
  };

  const handleQuantityChange = (itemId, quantity) => {
    setQuantities({ ...quantities, [itemId]: quantity });
  };

  const handleImageChange = (itemId, event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImages({ ...images, [itemId]: reader.result }); // Store the Base64 string
      };
      reader.readAsDataURL(file);
    }
  };

  const calculateTotalAmount = () => cartItems.reduce((total, item) => total + Number(item.price) * quantities[item.id], 0);

  return (
    <div className="product-list-container cart-page-container">
  <h3 className="cart-page-heading">Your Easy Shopping Cart: Great Deals Await</h3>
  <div className="product-list">
    {cartItems.map((item) => (
      <div key={item.id} className="product-item cart-item">
        <div className="image-container">
          <img className="product-image" src={item.image} alt={item.name} />
        </div>
        <div className="item-details">
          <h3 className="product-name">{item.name}</h3>
          <p className="product-price">Price: Rs. {item.price}</p>
          {item.selectedOption && (
           <p className="product-option">Option: {item.selectedOption}</p>
           )}
         <div 
  className="quantity-input-container" 
  style={{ 
    display: 'flex', 
    flexDirection: 'column', 
    marginBottom: '10px', 
    alignItems: 'center' // This centers the child elements horizontally.
  }}
>
  <label style={{ marginBottom: '5px' }}>Quantity:</label>
  <input
    type="number"
    className="quantity-input"
    value={quantities[item.id]}
    onChange={(e) => handleQuantityChange(item.id, e.target.value)}
    min="1"
    style={{ padding: '5px', maxWidth: '50px' }}
  />
</div>

{item.allow_customization && (
  <div 
    style={{ 
      display: 'flex', 
      flexDirection: 'column', 
      marginBottom: '10px', 
      alignItems: 'center' // This centers the child elements horizontally.
    }}
  >
    <input 
      className="custom-text-input" 
      type="text" 
      value={customTexts[item.id]} 
      onChange={(e) => handleCustomTextChange(item.id, e.target.value)} 
      placeholder="Custom Text" 
      style={{ padding: '5px', maxWidth: '100px' }} // Assuming you want to apply this style to both inputs
    />
    <input 
      className="file-input" 
      type="file" 
      onChange={(e) => handleImageChange(item.id, e)} 
      style={{ padding: '5px', maxWidth: '200px', marginTop: '5px' }} // Added marginTop for spacing between inputs
    />
  </div>
       )}
<button
  className="remove-button1"
  onClick={() => handleRemoveItem(item.id)}
  style={{
    position: 'absolute',
    bottom: '3px', // Adds 20px margin at the bottom
    left: '50%', // Centers the button horizontally
    transform: 'translateX(-50%)', // Corrects the centering by moving it half its width to the left
    width: '30%', // Adjust the width as needed
    // Include other style properties as needed
  }}
>
  Remove
</button>


        </div>
      </div>
    ))}
  </div>
  
  <div
  style={{
    
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center', // Centers text of all child elements
  }}
  className="content-wrapper"
>
  <div className="delivery-address-container">
    <label htmlFor="deliveryAddress" className="delivery-address-label">Delivery Address:</label>
    <input
      type="text"
      id="deliveryAddress"
      value={deliveryAddress}
      onChange={(e) => setDeliveryAddress(e.target.value)}
      placeholder="Enter delivery address"
      style={{
        width: '100%',
        maxWidth: '500px', // Adjusted for better fit
        boxSizing: 'border-box',
        padding: '10px',
        margin: '0 auto',
        border: '1px solid #ccc',
      }}
      className="delivery-address-input"
    />
  </div>

  <div className="total-amount">
    Total Amount: Rs. {calculateTotalAmount()}
  </div>
  <button className="make-payment-button" onClick={handleSubmit}>
    Make Payment
  </button>
</div>
</div>

  );
};

export default CartPage;
