import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { debounce } from 'lodash';
import './NavigationBar.css';

function NavigationBar({ cartItems, onLogoClick, user, onLogout }) {
  const [logoUrl, setLogoUrl] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();
  const [isSearching, setIsSearching] = useState(false);


  useEffect(() => {
    const fetchLogoUrl = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/logo/`);

        if (!response.ok) {
          throw new Error('Failed to fetch logo URL');
        }
        const data = await response.json();
        setLogoUrl(data.image_url); // Assuming your API returns the logo image URL
      } catch (error) {
        console.error('Error fetching logo URL:', error);
      }
    };

    fetchLogoUrl();
  }, []);



  const handleSearchInputChange = (event) => {
    const value = event.target.value;
    setSearchQuery(value);
    
    // Indicate that search operation is pending
    setIsSearching(true);
  
    if (value.trim()) {
      debouncedNavigate(value.trim());
    } else {
      // Immediately clear search state if input is cleared
      setIsSearching(false);
    }
  };
  
  // Adjust the debouncedNavigate function to include setIsSearching(false)
  const debouncedNavigate = debounce((query) => {
    navigate(`/products?search=${encodeURIComponent(query)}`);
    setIsSearching(false); // Reset searching state once navigation is triggered
  }, 500);
  
  const handleLogout = () => {
    onLogout();
    navigate('/');
  };

  const handleCartClick = () => {
    if (user) {
      navigate('/cart');
    } else {
      navigate('/login');
    }
  };

  const handleOrderHistoryClick = () => {
    navigate('/order-history');
  };
  
  const updatedHandleLogoClick = (e) => {
    e.preventDefault(); // Prevent the default anchor behavior
    setSearchQuery(''); // Clear the search query
    if (typeof onLogoClick === 'function') {
      onLogoClick(); // Call the onLogoClick prop
    }
    navigate(`/`); // Navigate to the homepage
  };

  

  return (
    <nav className="sticky-nav">
     <a href="/" onClick={updatedHandleLogoClick} className="logo">
        {logoUrl ? (
          <img src={logoUrl} alt="Logo" className="logo-image" />
        ) : (
          <span className="logo-placeholder">Loading logo...</span>
        )}
      </a>

      <div className="search-box">
      <input
  type="text"
  placeholder={isSearching ? "Searching..." : "Search"}
  value={searchQuery}
  onChange={handleSearchInputChange}
/>
      </div>
      <div className="nav-links">
        {user ? (
          <div className="user-details">
            <span className="user-welcome">Welcome, {user.fullName}</span>
            <div className="user-icon-wrapper">
              <FontAwesomeIcon icon={faUserCircle} className="user-icon" />
              <div className="user-menu">
                <span className="menu-item" onClick={handleOrderHistoryClick}>Order History</span>
                <span className="menu-item" onClick={handleLogout}>Logout</span>
              </div>
            </div>
          </div>
        ) : (
          <Link to="/login" className="login-link">Login / Signup</Link>
        )}
        <div className="cart-link" onClick={handleCartClick}>
          <FontAwesomeIcon icon={faShoppingCart} className="cart-icon" />
          <span className="cart-count">{cartItems ? cartItems.length : 0}</span>
        </div>
      </div>
    </nav>
  );
}

export default NavigationBar;
