import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import './ProductCategories.css';

const ProductsPage = ({ handleAddToCart }) => { // Destructure handleAddToCart from props
  // Get the search query parameter from the URL
  const [searchParams] = useSearchParams();
  const searchQuery = searchParams.get('search') || '';

  // Define state variables for products, loading state, error state, and selected options
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState({});

  // Fetch products from the API based on the search query parameter
  useEffect(() => {
    setLoading(true);
    setError(null);

    // Construct the API URL with the search query
    const apiUrl = `${process.env.REACT_APP_API_URL}/api/products_filter/?search=${encodeURIComponent(searchQuery)}`;


    // Fetch products from the API
    fetch(apiUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to fetch products');
        }
        return response.json();
      })
      .then((data) => {
        // Update the products state with the fetched products
        setProducts(data.products);
        setLoading(false);
      })
      .catch((error) => {
        // Handle errors
        console.error('Error fetching products:', error);
        setError(error);
        setLoading(false);
      });
  }, [searchQuery]); // Fetch products whenever the search query changes

  // Event handler for option change
  const handleOptionChange = (option, productId) => {
    setSelectedOptions({ ...selectedOptions, [productId]: option });
  };

  // Event handler for adding product to cart
  const addToCart = (product) => {
    // Check if the product has options and if an option is selected
    if (product.options && !selectedOptions[product.id]) {
      alert('Please select an option before adding to cart.');
      return;
    }
  
    // Add the product to the cart with the selected option
    const productWithOptions = product.options
      ? { ...product, selectedOption: selectedOptions[product.id] || '' }
      : product;
    handleAddToCart(productWithOptions, selectedOptions[product.id] || '');
  };

  // Render loading state
  if (loading) {
    return <div>Loading...</div>;
  }

  // Render error state
  if (error) {
    return <div>Error: {error.message}</div>;
  }

  // Render products
  return (
    <div className="product-list-container">
      <div className="product-list">
        {products.map((product) => (
          <div key={product.id} className="product-item">
            <img src={product.image} alt={product.name} />
            <h3>{product.name}</h3>
            <p className="product-price">Price: Rs. {Number(product.price).toLocaleString()}</p>
            {product.options && (
              <select onChange={(e) => handleOptionChange(e.target.value, product.id)} value={selectedOptions[product.id] || ''}>
                <option value="">Select Option</option>
                {product.options.map((option) => (
                  <option key={option.id} value={option.name}>
                    {option.name}
                  </option>
                ))}
              </select>
            )}
            <button onClick={() => addToCart(product)}>Add to Cart</button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductsPage;
