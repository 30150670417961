import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import './LoginForm.css';

// Define base URL from environment variable
const baseUrl = `${process.env.REACT_APP_API_URL}/api`;

function LoginForm({ onLogin }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState('');
  const navigate = useNavigate();

  // Function to handle email change
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  // Function to handle password change
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  // Fetch CSRF token when component mounts
  useEffect(() => {
    const fetchCsrfToken = async () => {
      try {
        const response = await axios.get(`${baseUrl}/get_csrf_token/`);
        // Set CSRF token from the response to cookies
        Cookies.set('csrftoken', response.data.csrf_token);
      } catch (error) {
        console.error('Failed to fetch CSRF token:', error);
      }
    };
    fetchCsrfToken();
  }, []);

  // Function to handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    const csrfToken = Cookies.get('csrftoken'); // Get CSRF token from cookies
    const loginUrl = `${baseUrl}/login/`;

    try {
      const response = await axios.post(loginUrl, {
        email,
        password,
      }, {
        headers: {
          'X-CSRFToken': csrfToken, // Include CSRF token in your request header
        },
      });

      onLogin(response.data.user);
      navigate('/'); // Navigate to home or dashboard
    } catch (error) {
      console.error('Login failed:', error.response ? error.response.data : 'Error occurred');
      setLoginError('Login failed. Please check your credentials.');
    }
  };

  return (
    <div className="login-form-container">
      <h2>Login</h2>
      {loginError && <div className="login-error-message">{loginError}</div>}
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={handleEmailChange}
            placeholder="Enter your email"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={handlePasswordChange}
            placeholder="Enter your password"
            required
          />
        </div>
        <button type="submit">Login</button>
        {loginError && <p className="error">{loginError}</p>}
        <div className="form-links">
          <Link to="/forgot-password" className="forgot-password-link">Forgot Password?</Link>
          <div className="signup-link">
            Don't have an account? <Link to="/signup">Sign Up</Link>
          </div>
        </div>
      </form>
    </div>
  );
}

export default LoginForm;
