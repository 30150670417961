import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './PasswordResetRequest.css';

const baseUrl = `${process.env.REACT_APP_API_URL}/api`;


function PasswordResetRequest() {
    const navigate = useNavigate();
    const [userData, setUserData] = useState({
        email: '',
        otp: '',
        newPassword: '',
        confirmNewPassword: '',
    });

    const [successMsg, setSuccessMsg] = useState('');
    const [errorMsg, setErrorMsg] = useState('');

    const handleChange = (event) => {
        setUserData({
            ...userData,
            [event.target.name]: event.target.value
        });
    };

    const sendOTP = () => {
        const formData = new FormData();
        formData.append('email', userData.email);
        try {
            axios.post(`${baseUrl}/user-forgot-password/`, formData)
            .then((res) => {
                if(res.data.bool === true){
                    setSuccessMsg(res.data.msg);
                    setErrorMsg('');
                }else{
                    setErrorMsg(res.data.msg);
                    setSuccessMsg('');
                }
            });
        } catch (error) {
            console.log(error);
        }
    };

    const updatePassword = () => {
        if(userData.newPassword !== userData.confirmNewPassword){
            setErrorMsg("Passwords do not match");
            return;
        }
        // API call to update password
        const formData = new FormData();
        formData.append('email', userData.email);
        formData.append('otp', userData.otp);
        formData.append('newPassword', userData.newPassword);
        try {
            axios.post(`${baseUrl}/user-update-password/`, formData)
            .then((res) => {
                if(res.data.bool === true){
                    setSuccessMsg(res.data.msg);
                    setErrorMsg('');
                    navigate('/login'); // Redirect to login on successful password reset
                }else{
                    setErrorMsg(res.data.msg);
                    setSuccessMsg('');
                }
            });
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        // Redirect if user is already logged in
        const userLoginStatus = localStorage.getItem('userLoginStatus');
        if (userLoginStatus === 'true') {
            navigate('/');
        }

        document.title = 'Reset Password';
    }, [navigate]);

    return (
        // Inside your return statement
<div className="container mt-4 password-reset-container">
    <div className="row">
        <div className="col-6 offset-3">
            <div className="card password-reset-card">
                <h5 className="card-header password-reset-header">Reset Password</h5>
                <div className="card-body">
                    {successMsg && <p className='text-success success-message'>{successMsg}</p>}
                    {errorMsg && <p className='text-danger error-message'>{errorMsg}</p>}
                    <div className="mb-3">
                        <label htmlFor="email" className="form-label">Email</label>
                        <input type="email" value={userData.email} name='email' onChange={handleChange} className="form-control email-input" id="email" />
                        <button onClick={sendOTP} className="btn btn-secondary mt-2 send-otp-btn">Send OTP</button>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="otp" className="form-label">OTP</label>
                        <input type="text" value={userData.otp} name='otp' onChange={handleChange} className="form-control otp-input" id="otp" />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="newPassword" className="form-label">New Password</label>
                        <input type="password" value={userData.newPassword} name='newPassword' onChange={handleChange} className="form-control new-password-input" id="newPassword" />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="confirmNewPassword" className="form-label">Confirm New Password</label>
                        <input type="password" value={userData.confirmNewPassword} name='confirmNewPassword' onChange={handleChange} className="form-control confirm-new-password-input" id="confirmNewPassword" />
                    </div>
                    <button onClick={updatePassword} className="btn btn-primary reset-password-btn">Reset Password</button>
                </div>
            </div>
        </div>
    </div>
</div>
    );
}

export default PasswordResetRequest;
