import React, { useState, useEffect } from 'react';
const baseUrl = `${process.env.REACT_APP_API_URL}/api`;
const PrivacyPolicy = () => {
   // State to store the fetched data
   const [privacyPolicy, setPrivacyPolicy] = useState({});

   // Function to fetch Privacy Policy content
   const fetchPrivacyPolicyContent = async () => {
       try {
        const response = await fetch(`${baseUrl}/privacy-policy/`);

           const data = await response.json();
           setPrivacyPolicy(data); // Update the state with the fetched Privacy Policy data
       } catch (error) {
           console.error("Error fetching Privacy Policy content:", error);
       }
   };

   // useEffect to fetch data on component mount
   useEffect(() => {
       fetchPrivacyPolicyContent();
   }, []); // Empty dependency array means this effect runs once on mount

   return (
      <div>
           <h2 style={{textAlign: 'center'}}>{privacyPolicy.title}</h2>
           {/* Rendering the content as HTML */}
           {/* Make sure your content is sanitized to avoid XSS attacks */}
           <div dangerouslySetInnerHTML={{ __html: privacyPolicy.content }} />
           <p>Last Updated: {new Date(privacyPolicy.last_updated).toLocaleString()}</p>
       </div>
   );
};

export default PrivacyPolicy;
