import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import NavigationBar from './components/NavigationBar';
import Footer from './components/Footer';
import ProductCategories from './components/ProductCategories';
import SlidingBanner from './components/SlidingBanner';
import ProductList from './components/ProductList';
import ProductsPage from './components/ProductsPage'; // Adjust the import path as necessary
import LoginPage from './pages/LoginForm';
import SignupForm from './pages/SignupForm';
import AboutUs from './pages/AboutUs';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsAndConditions from './pages/TermsAndConditions';
import OrderHistoryPage from './pages/OrderHistoryPage';
import RefundPolicy from './pages/RefundPolicy';
import CartPage from './pages/CartPage';
import PasswordResetRequest from './pages/PasswordResetRequest';

function Home({ handleAddToCart, selectedCategory, setSelectedCategory, searchQuery, setSearchQuery }) {
  const onCategoryClick = (categoryId) => {
    setSelectedCategory({ id: categoryId });
    setSearchQuery(''); // Reset search query when category is clicked
  };

  return (
    <>
      <SlidingBanner />
      <ProductCategories onCategoryClick={onCategoryClick} />
      <ProductList selectedCategory={selectedCategory} handleAddToCart={handleAddToCart} searchQuery={searchQuery} />
    </>
  );
}

function App() {
  const [cartItems, setCartItems] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [user, setUser] = useState(null);
  const [obfuscatedId, setObfuscatedId] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    const storedUser = localStorage.getItem('currentUser');
    if (storedUser) {
      const userData = JSON.parse(storedUser);
      setUser(userData);
      setIsLoggedIn(true);
      setObfuscatedId(generateObfuscatedId());
    }
  }, []);

  const handleAddToCart = (product) => {
    setCartItems(prevCartItems => [...prevCartItems, product]);
  };

  const handleRemoveItem = (itemId) => {
    setCartItems(prevCartItems => prevCartItems.filter((item) => item.id !== itemId));
  };

  const clearCart = () => {
    setCartItems([]);
  };

  const resetCategory = () => {
    setSelectedCategory(null);
  };

  const handleLogin = (userData) => {
    localStorage.setItem('currentUser', JSON.stringify(userData));
    setUser(userData);
    setIsLoggedIn(true);
    setObfuscatedId(generateObfuscatedId());
  };

  const handleLogout = () => {
    localStorage.removeItem('currentUser');
    setUser(null);
    setIsLoggedIn(false);
    setObfuscatedId(null);
  };

  const generateObfuscatedId = () => {
    return 'randomized_id';
  };

  return (
    <Router>
      <NavigationBar 
        cartItems={cartItems}
        user={user}
        isLoggedIn={isLoggedIn}
        onLogout={handleLogout}
        onLogoClick={resetCategory}
        obfuscatedId={obfuscatedId}
        setSearchQuery={setSearchQuery} // Pass setSearchQuery to NavigationBar
      />
      <div className="main-content">
        <Routes>
          <Route path="/login" element={<LoginPage onLogin={handleLogin} />} />
          <Route path="/forgot-password" element={<PasswordResetRequest />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-conditions" element={<TermsAndConditions />} />
          <Route path="/refund-policy" element={<RefundPolicy />} />
          <Route
            path="/products"
            element={<ProductsPage handleAddToCart={handleAddToCart} />}
         />

          <Route path="/signup" element={<SignupForm />} />
          <Route path="/order-history" element={<OrderHistoryPage />} />
          <Route 
            path="/cart" 
            element={<CartPage cartItems={cartItems} removeItem={handleRemoveItem} clearCart={clearCart} user={user} />} 
          />
          <Route 
            path="/" 
            element={
              <Home 
                handleAddToCart={handleAddToCart} 
                selectedCategory={selectedCategory} 
                setSelectedCategory={setSelectedCategory} 
                searchQuery={searchQuery} 
                setSearchQuery={setSearchQuery} // Pass setSearchQuery to Home
              />
            } 
          />
        </Routes>
      </div>
      <Footer isAuthenticated={isLoggedIn} />
    </Router>
  );
}

export default App;
