// eslint-disable-next-line
import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App'; // Adjust the import path as needed
import { AuthProvider } from './components/AuthContext'; // Make sure this path is correct!
import reportWebVitals from './reportWebVitals';

// Create a root.
const container = document.getElementById('root');
const root = createRoot(container);

// Initial render: Render the App component within AuthProvider to the root.
root.render(
  <React.StrictMode>
    <AuthProvider> {/* Wrap App with AuthProvider */}
      <App />
    </AuthProvider>
  </React.StrictMode>
);

// Your reportWebVitals call remains the same
reportWebVitals();
