import React, { useState, useEffect } from 'react';
import './ProductList.css';
import Spinner from './Spinner'; // Ensure the Spinner path is correctly set

const ProductList = ({ handleAddToCart, selectedCategory }) => {
  const [products, setProducts] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    setCurrentPage(1);
  }, [selectedCategory]);

  useEffect(() => {
    setLoading(true);
    setError(null);

    const limit = 11; // Number of items per page
    const apiUrl = selectedCategory
      ? `${process.env.REACT_APP_API_URL}/api/products/?category=${selectedCategory.id}&page=${currentPage}&limit=${limit}`
      : `${process.env.REACT_APP_API_URL}/api/products/?page=${currentPage}&limit=${limit}`;

    fetch(apiUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to fetch products');
        }
        return response.json();
      })
      .then((data) => {
        if (!Array.isArray(data.products) || data.products.length === 0) {
          throw new Error('No products found');
        }

        // Sort all products by ID in descending order BEFORE pagination
        const sortedProducts = data.products.sort((a, b) => b.id - a.id);

        // Client-side pagination
        const totalItems = sortedProducts.length;
        const startIndex = (currentPage - 1) * limit;
        const paginatedProducts = sortedProducts.slice(startIndex, startIndex + limit);

        setProducts(paginatedProducts);
        setTotalPages(Math.ceil(totalItems / limit));
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching products:', error);
        setError(error.toString());
        setLoading(false);
      });
  }, [selectedCategory, currentPage]); // <-- Closing the useEffect correctly

  const handleOptionChange = (option, productId) => {
    setSelectedOptions((prevOptions) => ({ ...prevOptions, [productId]: option }));
  };

  const addToCart = (product) => {
    if (product.options && !selectedOptions[product.id]) {
      alert('Please select an option before adding to cart.');
      return;
    }
    const productWithOptions = product.options
      ? { ...product, selectedOption: selectedOptions[product.id] || '' }
      : product;
    handleAddToCart(productWithOptions, selectedOptions[product.id] || '');
    alert(`Added to cart: ${product.name} - Rs. ${Number(product.price).toLocaleString()} - ${selectedOptions[product.id] || 'No option'}`);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  if (loading) {
    return (
      <div className="product-list-loading">
        Loading products... <Spinner />
      </div>
    );
  }

  if (error) {
    return (
      <div className="product-list-error">
        Error loading products: {error}
        <button onClick={() => window.location.reload()}>Retry</button>
      </div>
    );
  }

  if (products.length === 0) {
    return <div className="product-list-error">No products available.</div>;
  }

  return (
    <div className="product-list-container">
      <div className="product-list">
        {products.map((product) => (
          <div
            key={product.id}
            className="product-item"
            style={{ border: product.allow_customization ? '2.5px solid darkgreen' : '1px solid #ccc' }}
          >
            <img src={product.image} alt={product.name} />
            <h3>{product.name}</h3>
            <p className="product-price">Price: Rs. {Number(product.price).toLocaleString()}</p>
            {product.options && (
              <select
                onChange={(e) => handleOptionChange(e.target.value, product.id)}
                value={selectedOptions[product.id] || ''}
              >
                <option value="">Select Option</option>
                {product.options.map((option) => (
                  <option key={option.id} value={option.name}>
                    {option.name}
                  </option>
                ))}
              </select>
            )}
            <button onClick={() => addToCart(product)}>Add to Cart</button>
            {product.allow_customization && (
              <div className="customization-message">
                Customize this product with text and images.
              </div>
            )}
          </div>
        ))}
      </div>
      <div className="pagination-controls">
        <button onClick={handlePreviousPage} disabled={currentPage === 1}>
          Previous
        </button>
        <span>Page {currentPage} of {totalPages}</span>
        <button onClick={handleNextPage} disabled={currentPage === totalPages}>
          Next
        </button>
      </div>
    </div>
  );
};

export default ProductList;
