import React, { useState, useEffect } from 'react';

const AboutUs = () => {
    // State to store the fetched data
    const [aboutUs, setAboutUs] = useState({});

    // Function to fetch About Us content
    const fetchAboutUsContent = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/about-us/`);

            const data = await response.json();
            setAboutUs(data);
        } catch (error) {
            console.error("Error fetching About Us content:", error);
        }
    };

    // useEffect to fetch data on component mount
    useEffect(() => {
        fetchAboutUsContent();
    }, []); // Empty dependency array means this effect runs once on mount

    return (
        <div>
            <h2 style={{textAlign: 'center'}}>{aboutUs.title}</h2>
            {/* Rendering the content as HTML */}
            {/* Make sure your content is sanitized to avoid XSS attacks */}
            <div dangerouslySetInnerHTML={{ __html: aboutUs.content }} />
            <p>Last Updated: {new Date(aboutUs.last_updated).toLocaleString()}</p>
        </div>
    );
};



export default AboutUs;
