import React, { useState, useEffect } from 'react';
const baseUrl = `${process.env.REACT_APP_API_URL}/api`;

const TermsAndConditions = () => {
   // State to store the fetched data
   const [termsAndConditions, setTermsAndConditions] = useState({});

   // Function to fetch Terms and Conditions content
   const fetchTermsAndConditionsContent = async () => {
       try {
        const response = await fetch(`${baseUrl}/terms-and-conditions/`);

           const data = await response.json();
           setTermsAndConditions(data); // Update the state with the fetched data
       } catch (error) {
           console.error("Error fetching Terms and Conditions content:", error);
       }
   };

   // useEffect to fetch data on component mount
   useEffect(() => {
       fetchTermsAndConditionsContent();
   }, []); // Empty dependency array means this effect runs once on mount

   return (
       <div>
           <h2 style={{textAlign: 'center'}}>{termsAndConditions.title}</h2>
           {/* Rendering the content as HTML */}
           {/* Make sure your content is sanitized to avoid XSS attacks */}
           <div dangerouslySetInnerHTML={{ __html: termsAndConditions.content }} />
           <p>Last Updated: {new Date(termsAndConditions.last_updated).toLocaleString()}</p>
       </div>
   );
};

export default TermsAndConditions;
