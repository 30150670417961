import React from 'react';
import { Link } from 'react-router-dom';
import './footer.css';

const Footer = ({ isAuthenticated }) => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section2">
          <ul className="footer-links">
            <li><Link to="/about-us">About Us</Link></li>
            <li><Link to="/privacy-policy">Privacy Policy</Link></li>
            <li><Link to="/terms-conditions">Terms & Conditions</Link></li>
            <li><Link to="/refund-policy">Refund Policy</Link></li>
          </ul>
        </div>

        <div className="footer-section">
          
            <p>Contact Us: admin@middleman.ind.in<br /> <br />
          © {new Date().getFullYear()} Middle Man. All rights reserved.<br /><br />
            
               This platform is developed by T.P. Thangaprabhu.<br />
               For inquiries or support, contact us at 98430 27787 or 99430 07270.
            </p>


          </div>
        

        <div className="footer-section1">
          <div className="footer-auth">
            {isAuthenticated ? (
              <span>Login / Sign Up</span>
            ) : (
              <>
                <Link to="/login">Login</Link> / <Link to="/signup">Sign Up</Link>
              </>
            )}
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
