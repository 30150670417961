import React, { useState, useEffect } from 'react';
import './OrderHistoryPage.css';
const baseUrl = `${process.env.REACT_APP_API_URL}/api`;

function OrderHistoryPage() {
  const [orderHistory, setOrderHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchOrderHistory = async () => {
      try {
        const currentUser = JSON.parse(localStorage.getItem('currentUser'));
        if (!currentUser || !currentUser.id) {
          throw new Error('User ID not found');
        }
        const response = await fetch(`${baseUrl}/order-history/${currentUser.id}`, {
          headers: {
            'Content-Type': 'application/json',
            // 'Authorization': 'Bearer your_token_here', // Uncomment and replace if you use token-based auth
          }
        });
  
        if (!response.ok) {
          throw new Error('Failed to fetch order history');
        }
        const data = await response.json();
        setOrderHistory(data.order_history);
        setError('');
      } catch (error) {
        setError(error.message || 'An error occurred while fetching order history.');
      } finally {
        setIsLoading(false);
      }
    };
  
    fetchOrderHistory();
  }, []);
  

  if (isLoading) {
    return <p>Loading order history...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  const sortedOrderHistory = [...orderHistory].sort((a, b) => b.id - a.id);


  return (
    <div class="table-container">
      <div class="sticky-header">
        <h2>Order History</h2>
      </div>
      <table className="styledTable">
        <thead>
          <tr>
            <th>Order ID</th>
            <th>Product Name</th>
            <th>Selected Option</th>
            <th>Price</th>
            <th>Quantity</th>
            <th>Total</th>
            <th>Shipping Address</th>
            <th>Order Status</th>
            <th>Custom Text</th>
            <th>Image</th>
          </tr>
        </thead>
        <tbody>
        {sortedOrderHistory.map((order) => 
            order.items && order.items.length > 0 ? (
              order.items.map((item, index) => (
                <tr key={`${order.id}-${item.product_id}-${index}`}>
                  {index === 0 ? (
                    <>
                      <td rowSpan={order.items.length}>{order.id}</td>
                      {/* Placeholder cells for rowspan to align with the first item */}
                    </>
                  ) : null}
                  <td>{item.product_name}</td>
                  <td>{item.selected_option}</td>
                  <td>{item.price}</td>
                  <td>{item.quantity}</td>
                  {index === 0 ? (
                    // Total, Shipping Address, and Custom Text only need to be shown once, aligned with the first item of each order
                    <>
                      <td rowSpan={order.items.length}>{order.total}</td>
                      <td rowSpan={order.items.length}>{order.shipping_address}</td>
                    </>
                  ) : null}
                  <td>{order.status}</td>
                  <td>{item.custom_text}</td>
                  <td>
                    {item.image ? (
                      <img
                        src={`${process.env.REACT_APP_API_URL}${item.image}`}
                        alt="Product"
                        style={{ width: '50px', height: '50px' }}
                      />
                    ) : 'No Image'}
                  </td>
                </tr>
              ))
            ) : (
              <tr key={order.id}>
                <td>{order.id}</td>
                <td colSpan={8}>No items in this order.</td>
              </tr>
            )
          )}
        </tbody>
      </table>
    </div>
  );
  
};

export default OrderHistoryPage;