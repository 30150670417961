import React, { useState, useEffect } from 'react';
import './ProductCategories.css';

const ProductCategories = ({ onCategoryClick }) => {
  const [categories, setCategories] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/categories/`);

        if (!response.ok) {
          throw new Error(`Failed to fetch categories: ${response.status}`);
        }
        const data = await response.json();
        setCategories(data.categories);
      } catch (error) {
        console.error('Error fetching categories:', error);
        setError(error);
      }
    };

    fetchCategories();
  }, []);

  const handleCategoryClick = (categoryId, event) => {
    event.preventDefault(); // Prevent default button click behavior
  
    // Introduce a delay before calling the onCategoryClick handler
    setTimeout(() => {
      onCategoryClick(categoryId);
    }, 250); // Delay in milliseconds
  };
  
  

  return (
    <div className="product-categories-container">
      <div className="product-categories">
        {categories.map((category) => (
          <div key={category.id} className="category" onClick={(e) => handleCategoryClick(category.id, e)}>

            <button>
            <img src={`${process.env.REACT_APP_API_URL}${category.image_url}`} alt={category.name} />

              <span>{category.name}</span>
            </button>
          </div>
        ))}
        {categories.length === 0 && !error && (
          <div className="no-categories-message">No categories available.</div>
        )}
      </div>
    </div>
  );
};

export default ProductCategories;
