import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import './SignupForm.css';


const baseUrl = `${process.env.REACT_APP_API_URL}/api`;

function SignupForm() {
  const [csrfToken, setCsrfToken] = useState('');
  const [stage, setStage] = useState(1); // 1: Email input for OTP, 2: OTP verification, 3: Full registration
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [fullName, setFullName] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const [address, setAddress] = useState('');
  const [gender, setGender] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch CSRF token when component mounts
    const fetchCsrfToken = async () => {
      try {
        const response = await axios.get(`${baseUrl}/get_csrf_token/`);
        
        setCsrfToken(response.data.csrf_token); // Update this line to match the key from the backend
        
      } catch (error) {
        
      }
    };
    fetchCsrfToken();
  }, []);
  
  

  const sendOTP = async () => {
    if (!csrfToken) {
      setErrorMessage('CSRF token missing. Please refresh the page or clear your cookies.');
      return;
    }

    try {
      const response = await axios.post(`${baseUrl}/send-otp/`, { email: email }, {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrfToken // Include CSRF token in the request header
        },
      });
      setSuccessMessage(response.data.message);
      setStage(2); // Move to OTP verification
    } catch (error) {
      console.error('Failed to send OTP:', error.response ? error.response.data : 'Error occurred');
      setErrorMessage('Failed to send OTP. Please try again.');
    }
  };



  const verifyOTP = async () => {
    try {
      const response = await axios.post(`${baseUrl}/verify-otp/`, { email, otp }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      setSuccessMessage(response.data.message);
      setStage(3); // Move to full registration form
    } catch (error) {
      console.error('Error verifying OTP:', error.response ? error.response.data : 'Error occurred');
      setErrorMessage('Invalid or expired OTP. Please try again.');
    }
  };
  
  

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (password !== confirmPassword) {
      setErrorMessage('Passwords do not match.');
      return;
    }
    const csrfToken = Cookies.get('csrftoken');
    try {
      await axios.post(`${baseUrl}/register/`, {
        username: email,
        email,
        password,
        profile: {
          fullName,
          contactNumber,
          address,
          gender,
          dateOfBirth,
        },
      }, {
        headers: {
          'X-CSRFToken': csrfToken,
        },
      });
      setSuccessMessage('Registration successful. Redirecting to login...');
      setTimeout(() => navigate('/login'), 3000);
    } catch (error) {
      console.error('Signup failed:', error.response ? error.response.data : 'Error occurred');
      setErrorMessage('Signup failed. Please try again.');
    }
  };

  return (
    <div className="signup-form-container">
      <h2>{stage === 1 ? "Request OTP" : stage === 2 ? "Verify OTP" : "Sign Up"}</h2>
      {successMessage && <div className="success-message">{successMessage}</div>}
      {errorMessage && <div className="error-message">{errorMessage}</div>}
      {stage === 1 && (
        <form onSubmit={(e) => { e.preventDefault(); sendOTP(); }}>
          <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" required />
          <button type="submit">Send OTP</button>
        </form>
      )}
      {stage === 2 && (
        <form onSubmit={(e) => { e.preventDefault(); verifyOTP(); }}>
          <input type="text" value={otp} onChange={(e) => setOtp(e.target.value)} placeholder="Enter OTP" required />
          <button type="submit">Verify OTP</button>
        </form>
      )}
      {stage === 3 && (
        <form onSubmit={handleSubmit}>
          <input type="email" value={email} placeholder="Email" readOnly />
          <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" required />
          <input type="password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} placeholder="Confirm Password" required />
          <input type="text" value={fullName} onChange={(e) => setFullName(e.target.value)} placeholder="Full Name" required />
          <input type="text" value={contactNumber} onChange={(e) => setContactNumber(e.target.value)} placeholder="Contact Number" required />
          <input type="text" value={address} onChange={(e) => setAddress(e.target.value)} placeholder="Address" required />
          <select value={gender} onChange={(e) => setGender(e.target.value)} required>
            <option value="">Select Gender</option>
            <option value="male">Male</option>
            <option value="female">Female</option>
            <option value="other">Other</option>
          </select>
          <input type="date" value={dateOfBirth} onChange={(e) => setDateOfBirth(e.target.value)} placeholder="Date of Birth" required />
          <button type="submit">Sign Up</button>
        </form>
      )}
    </div>
  );
}

export default SignupForm;
